const GetStoreArea = () => {
  const storeArea = {
    areas: ["東京都", "関東", "近畿", "九州", "中部", "東北", "北海道"],
    detailAreas: {
      東京都: [
        "豊島区・練馬区・板橋区・北区",
        "東京市部",
        "渋谷区・世田谷区・目黒区",
        "品川区・大田区",
        "新宿区・中野区・杉並区",
        "台東区・墨田区・江東区・江戸川区",
        "荒川区・葛飾区・足立区",
        "千代田区・中央区・港区・文京区",
      ],
      関東: ["神奈川県", "千葉県", "埼玉県", "群馬県", "茨城県"],
      近畿: ["大阪府", "京都府", "奈良県", "兵庫県"],
      九州: ["福岡県", "佐賀県"],
      中部: ["石川県", "新潟県", "富山県", "愛知県"],
      東北: ["秋田県","宮城県"],
      北海道: ["札幌市", "その他地域"],
    },
  }
  return storeArea
}

export default GetStoreArea
