import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import Seo from "components/seo"
import PageTitle from "components/pageTitle"
import ServiceImage from "components/parts/serviceImage"
import { Link as ScrollLink } from "react-scroll"
import CheckStoreOpen from "service/store/checkStoreOpen"
import GetStoreArea from "service/store/getStoreArea"

const StorePage = ({ data }) => {
  const { areas, detailAreas } = GetStoreArea()

  // 元データ サービスを文字列から配列に変換
  const allStores = data.allStrapiStore.edges
  allStores.forEach(x => {
    if (typeof x.node.service === "string" || x.node.service instanceof String)
      x.node.service = x.node.service.split(";")
  })

  const [state, setState] = useState({
    targetArea: "",
    targetDetailArea: "",
    selectedAreas: [],
    filteredData: [],
    selectedService: [],
  })

  const {
    filteredData,
    targetArea,
    targetDetailArea,
    selectedAreas,
    selectedService,
  } = state
  const hasSearchResults =
    (filteredData && targetArea !== "") ||
    targetDetailArea !== "" ||
    selectedService.length > 0
  const stores = hasSearchResults ? filteredData : allStores

  // エリアフォームのイベント
  const handleOnAreaSelect = e => {
    const targetArea = e.target.value
    const selectedAreas = targetArea ? detailAreas[targetArea] : []
    setState({
      ...state,
      targetArea,
      targetDetailArea: "",
      selectedAreas,
    })
  }
  // 詳細エリアフォームのイベント
  const hundleOnDetailAreaSelect = e => {
    const targetDetailArea = e.target.value
    setState({
      ...state,
      targetDetailArea,
    })
  }

  // 実装終わったらリファクタ
  const filterStores = stores => {
    if (
      selectedService.length &&
      targetArea !== "" &&
      targetDetailArea !== ""
    ) {
      const result = stores.filter(store => {
        const { service, area, city } = store.node
        const duplicatedService = [...service, ...selectedService]
          .filter(x => service.includes(x) && selectedService.includes(x))
          .sort()
        const filterdService = [...new Set(duplicatedService)]
        return (
          filterdService.toString() === selectedService.toString() &&
          area === targetArea &&
          city === targetDetailArea
        )
      })
      return result
    }
    if (selectedService.length && targetArea !== "") {
      const result = stores.filter(store => {
        const { service, area } = store.node
        const duplicatedService = [...service, ...selectedService]
          .filter(x => service.includes(x) && selectedService.includes(x))
          .sort()
        const filterdService = [...new Set(duplicatedService)]
        return (
          filterdService.toString() === selectedService.toString() &&
          area === targetArea
        )
      })
      return result
    }
    if (selectedService.length) {
      const result = stores.filter(store => {
        const { service } = store.node
        const duplicatedService = [...service, ...selectedService]
          .filter(x => service.includes(x) && selectedService.includes(x))
          .sort()
        const filterdService = [...new Set(duplicatedService)]
        return filterdService.toString() === selectedService.toString()
      })
      return result
    }
    if (targetArea !== "" && targetDetailArea !== "") {
      const result = stores.filter(store => {
        const { area, city } = store.node
        return area === targetArea && city === targetDetailArea
      })
      return result
    }
    if (targetArea !== "") {
      const result = stores.filter(store => {
        const { area } = store.node
        return area === targetArea
      })
      return result
    }

    return stores
  }

  const storeSearch = () => {
    const stores = allStores
    const filteredData = filterStores(stores)
    setState({
      ...state,
      filteredData,
    })
  }

  const filterdService = e => {
    const target = e.target.value
    if (selectedService.includes(target)) {
      setState({
        ...state,
        selectedService: [
          ...selectedService.filter(
            selectedService => selectedService !== target
          ),
        ].sort(),
      })
    } else {
      setState({
        ...state,
        selectedService: [...selectedService.concat([target])].sort(),
      })
    }
  }

  useEffect(() => {
    storeSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService])

  return (
    <Layout>
      <Seo title="Store" />

      <div className="store-list-page design-style">
        <div className="contents-wrap">
          <div className="contents">
            <PageTitle title="Store" subTitle="お店を探す" />

            <div className="shop-search">
              {/* エリア検索*/}
              <div className="search-box area-search active">
                <form id="search-form">
                  <div className="select-item">
                    <label className="select-label fw-b" htmlFor="area">
                      エリア
                    </label>
                    <div className="select-wrap">
                      <select
                        className="fw-b"
                        id="area"
                        value={targetArea}
                        onChange={handleOnAreaSelect}
                      >
                        <option value="" hidden>
                          エリアを指定する
                        </option>
                        {areas.map((area, index) => {
                          return (
                            <option value={area} key={index}>
                              {area}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="select-item">
                    <label className="select-label fw-b" htmlFor="city">
                      詳細エリア
                    </label>
                    <div className="select-wrap">
                      <select
                        className="fw-b"
                        id="city"
                        onChange={hundleOnDetailAreaSelect}
                        value={targetDetailArea}
                      >
                        <option value="" hidden>
                          指定しない
                        </option>
                        {selectedAreas.map((detailArea, index) => {
                          return (
                            <option
                              value={detailArea}
                              key={index}
                              className="detail-area-input"
                            >
                              {detailArea}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="search-btn-wrap">
                    <ScrollLink
                      to="search-result"
                      smooth
                      offset={-87.5}
                      onClick={() => storeSearch()}
                      role="button"
                      tabIndex={0}
                      className="search-btn fw-b pc"
                    >
                      Search
                    </ScrollLink>
                    <ScrollLink
                      to="search-result"
                      smooth
                      offset={-64.5}
                      onClick={() => storeSearch()}
                      role="button"
                      tabIndex={0}
                      className="search-btn fw-b sp"
                    >
                      Search
                    </ScrollLink>
                  </div>
                </form>
              </div>
              {/* /エリア検索*/}

            </div>
            <div id="search-result" className="store-contents">
              {/* セクションタイトル */}
              <PageTitle title="Our Stores" subTitle="ストア一覧" />
              {/* /セクションタイトル */}
              <div className="select-service">
                <label className="service-filter fw-b">
                  <input
                    type="checkbox"
                    defaultValue="14"
                    onChange={filterdService}
                  />
                  <span>・</span>
                  <img
                    src="https://balukoweb.blob.core.windows.net/images/icon/service/black/i-14.png"
                    width={30}
                    height="auto"
                    alt=""
                    loading="lazy"
                  />
                  24時間営業
                </label>
                <label className="service-filter fw-b">
                  <input
                    type="checkbox"
                    defaultValue="13"
                    onChange={filterdService}
                  />
                  <span>・</span>
                  <img
                    src="https://balukoweb.blob.core.windows.net/images/icon/service/black/i-13.png"
                    width={30}
                    height="auto"
                    alt=""
                    loading="lazy"
                  />
                  駐車場あり
                </label>
                <label className="service-filter fw-b">
                  <input
                    type="checkbox"
                    defaultValue="09"
                    onChange={filterdService}
                  />
                  <span>・</span>
                  <img
                    src="https://balukoweb.blob.core.windows.net/images/icon/service/black/i-09.png"
                    width={30}
                    height="auto"
                    alt=""
                    loading="lazy"
                  />
                  カフェ
                </label>
                <label className="service-filter fw-b">
                  <input
                    type="checkbox"
                    defaultValue="19"
                    onChange={filterdService}
                  />
                  <span>・</span>
                  <img
                    src="https://balukoweb.blob.core.windows.net/images/icon/service/black/i-07.png"
                    width={30}
                    height="auto"
                    alt=""
                    loading="lazy"
                  />
                  クリーニング・洗濯代行
                </label>
                <label className="service-filter pc-mt20 fw-b">
                  <input
                    type="checkbox"
                    defaultValue="18"
                    onChange={filterdService}
                  />
                  <span>・</span>
                  <img
                    src="https://balukoweb.blob.core.windows.net/images/icon/service/black/i-18.png"
                    width={30}
                    height="auto"
                    alt=""
                    loading="lazy"
                  />
                  ペットランドリー
                </label>
                <label className="service-filter pc-mt20 fw-b">
                  <input
                    type="checkbox"
                    defaultValue="24"
                    onChange={filterdService}
                  />
                  <span>・</span>
                  <img
                    src="https://balukoweb.blob.core.windows.net/images/icon/service/black/i-24.png"
                    width={30}
                    height="auto"
                    alt=""
                    loading="lazy"
                  />
                  Balukoアプリ
                </label>
                <label className="service-filter pc-mt20 fw-b">
                  <input
                    type="checkbox"
                    defaultValue="25"
                    onChange={filterdService}
                  />
                  <span>・</span>
                  <img
                    src="https://balukoweb.blob.core.windows.net/images/icon/service/black/i-25.png"
                    width={30}
                    height="auto"
                    alt=""
                    loading="lazy"
                  />
                  Smart Laundryアプリ
                </label>
                <label className="service-filter pc-mt20 fw-b">
                  <input
                      type="checkbox"
                      defaultValue="22"
                      onChange={filterdService}
                  />
                  <span>・</span>
                  <img
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/black/i-22.png"
                      width={30}
                      height="auto"
                      alt=""
                      loading="lazy"
                  />
                  敷ふとんが洗える
                </label>
                <label className="service-filter pc-mt20 fw-b">
                  <input
                      type="checkbox"
                      defaultValue="26"
                      onChange={filterdService}
                  />
                  <span>・</span>
                  <img
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/black/i-26.png"
                      width={30}
                      height="auto"
                      alt=""
                      loading="lazy"
                  />
                  モンベル撥水コース
                </label>
              </div>

              <div className="search-result-text fw-b tx-center">
                {stores.length > 0 ? (
                  <span>{`該当店舗が${stores.length}件あります。`}</span>
                ) : (
                  <span>該当店舗はありません</span>
                )}
              </div>
              <div className="store-list">
                {stores.map(({ node, index }) => (
                  <React.Fragment key={index}>
                    <a
                      href={`/${node.page_slug}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="store"
                    >
                      <h3 className="store-name">{node.display_name}</h3>
                      <div className="store-image">
                        {CheckStoreOpen(node.opening_date) && (
                          <img src={node.image1} alt="" loading="lazy" />
                        )}
                        {!CheckStoreOpen(node.opening_date) && (
                          <img
                            src="https://balukoweb.blob.core.windows.net/images/store/form/before_open.jpg"
                            alt="coming soon"
                            loading="lazy"
                          />
                        )}
                      </div>
                      <ul className="service">
                        {node.service.map((service, index) => (
                          <ServiceImage service={service} index={index} />
                        ))}
                      </ul>
                    </a>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Store ー 店舗一覧"
    description="バルコ ランドリープレイスの店舗情報。関東を中心に、全国に店舗を展開。各ストアページではWEBで混雑状況を確認することもできます。"
  />
)

export default StorePage

export const query = graphql`
  query {
    allStrapiStore(sort: { order: DESC, fields: opening_date }) {
      edges {
        node {
          page_slug
          name
          display_name
          service
          image1
          city
          area
          latitude
          longitude
          opening_date
        }
      }
    }
  }
`
