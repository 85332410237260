import React from "react"

const ServiceImage = props => {
  const services = ["09", "13", "14", "18", "19", "22", "24", "25", "26"]
  if (services.includes(props.service)) {
    return (
      <React.Fragment key={props.index}>
        <li className="service-list">
          <img
            src={`https://balukoweb.blob.core.windows.net/images/icon/service/black/i-${props.service}.png`}
            alt=""
            loading="lazy"
          />
        </li>
      </React.Fragment>
    )
  }
}
export default ServiceImage
